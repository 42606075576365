export default {
  button: {
    font: {
      weight: 700
    },
    active: {
      font: {
        weight: 700
      }
    },
    disabled: {
      color: 'text-weak'
    }
  }
}
