import { css } from 'styled-components'
import { FormUp } from 'grommet-icons/icons/FormUp'
import { FormDown } from 'grommet-icons/icons/FormDown'

export default {
  control: {
    extend: ({ disabled }) => css`
      ${disabled &&
      `
      opacity: 0.3;
      input {
        cursor: default;
      }`}
    `
  },
  icons: {
    color: 'text',
    down: FormDown,
    up: FormUp
  },
  options: undefined
}
