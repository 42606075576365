import React from 'react'
import { Blank } from 'grommet-icons/icons/Blank'

export default {
  border: {
    color: 'border',
    width: 'xsmall'
  },
  check: {
    color: 'selected-background',
    background: {
      color: 'background-front'
    }
  },
  color: 'selected-background',
  container: {
    extend: ({ theme }) => `
    font-weight: 500;
    width: auto;
    padding: ${theme.global.edgeSize.xxsmall} ${theme.global.edgeSize.xsmall};
  `
  },
  extend: ({ theme }) => `
    padding: ${theme.global.edgeSize.xxsmall} ${theme.global.edgeSize.xsmall};
  `,
  gap: 'xsmall',
  hover: {
    background: {
      color: 'background-contrast'
    },
    border: {
      color: undefined
    }
  },
  icons: {
    circle: () => (
      <Blank color='selected-background'>
        <circle cx='12' cy='12' r='8' />
      </Blank>
    )
  }
}
