import React from 'react'
import { CircleAlert } from 'grommet-icons/icons/CircleAlert'

export default {
  content: {
    margin: { vertical: 'xsmall' },
    pad: undefined
  },
  border: {
    error: {
      color: 'border-strong'
    },
    color: 'border',
    side: 'all'
  },
  disabled: {
    background: {
      color: undefined
    },
    border: {
      color: 'border-weak'
    },
    label: {
      color: 'text-weak'
    }
  },
  error: {
    background: {
      color: 'validation-critical'
    },
    container: {
      gap: 'xsmall'
    },
    icon: <CircleAlert size='small' style={{ marginTop: '4px' }} />,
    size: 'xsmall',
    color: 'text',
    margin: {
      bottom: 'xsmall',
      top: 'none',
      horizontal: 'none'
    }
  },
  focus: {
    border: {
      color: 'border'
    }
  },
  help: {
    size: 'xsmall',
    color: 'text',
    margin: 'none'
  },
  info: {
    size: 'xsmall',
    color: 'text',
    margin: {
      bottom: 'xsmall',
      top: 'none',
      horizontal: 'none'
    }
  },
  label: {
    size: 'xsmall',
    color: 'text',
    margin: {
      bottom: 'none',
      top: 'xsmall',
      horizontal: 'none'
    },
    requiredIndicator: true,
    weight: 600
  },
  margin: {
    bottom: 'none'
  },
  round: '4px'
}
