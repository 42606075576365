export default {
  container: {
    background: 'background-front',
    elevation: 'medium',
    extend: 'transition: all 0.3s ease-in-out;'
  },
  body: {
    pad: 'medium'
  },
  footer: {
    pad: { horizontal: 'medium', vertical: 'small' }
  },
  header: {
    pad: 'medium'
  },
  hover: {
    container: {
      elevation: 'large'
    }
  }
}
