export default {
  header: {
    extend: `
      > div { 
        height: 100%;
        justify-content: center;
      }
      // align onSelect checkbox to center of header cell
      label { 
        justify-content: center;
      }
    `,
    // space for focus indicator on sortable columns
    pad: { left: 'none', vertical: 'none', right: 'xxsmall' }
  },
  body: {
    extend: ({ theme }) =>
      `
        :hover {
          button {
            background: ${
              theme.global.colors['background-contrast'][
                theme.dark ? 'dark' : 'light'
              ]
            }
          }
        }
      `
  },
  row: {
    hover: {
      background: 'background-contrast'
    }
  },
  footer: {
    extend: `
      font-weight: bold;
    `
  }
}
