import { css } from 'styled-components'

export default {
  color: 'text-strong',
  active: {
    background: 'background-contrast',
    color: 'text-strong'
  },
  hover: {
    background: 'background-contrast',
    color: 'text-strong'
  },
  border: {
    side: 'bottom',
    color: 'transparent',
    size: 'medium',
    active: {
      color: 'border-strong'
    },
    disabled: {
      color: undefined
    },
    hover: {
      color: undefined
    }
  },
  disabled: {
    color: 'text-weak'
  },
  pad: {
    // top and bottom pad need to be defined individually, specifying
    // "vertical" only applies to top
    bottom: 'small',
    top: 'small',
    horizontal: 'medium'
  },
  margin: {
    // bring the overall tabs border behind invidual tab borders
    vertical: '-2px',
    horizontal: 'none'
  },
  extend: ({ theme }) => css`
    border-top-left-radius: ${theme.global.control.border.radius};
    border-top-right-radius: ${theme.global.control.border.radius};
    font-weight: bold;
  `
}
