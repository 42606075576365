import { deepFreeze } from '../../utils'
import designTokens from './design-tokens'
import * as componentTokens from './component-tokens'

const Theme = deepFreeze({
  name: 'Zion Theme',
  defaultMode: 'dark',
  rounding: 4,
  scale: 1.1,
  spacing: 24,
  global: designTokens,
  ...componentTokens
})

export default Theme
