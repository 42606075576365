export default {
  hover: {
    border: {
      color: undefined
    },
    background: {
      color: 'background-contrast'
    }
  },
  color: 'background',
  border: {
    color: 'border',
    width: '1px'
  },
  focus: {
    border: {
      color: undefined
    },
    background: {
      color: 'background-contrast'
    }
  },
  check: {
    radius: '2px',
    extend: ({ theme, checked, indeterminate }) => `
    background-color: ${
      checked || indeterminate
        ? theme.global.colors.green[theme.dark ? 'dark' : 'light']
        : theme.global.colors.background[theme.dark ? 'dark' : 'light']
    };
    ${(checked || indeterminate) && 'border: none;'}
      `
  },
  icon: {
    extend: ({ theme }) => `stroke-width: 2px;
    stroke: ${
      theme.global.colors['text-strong'][theme.dark ? 'dark' : 'light']
    }`
  },
  gap: 'small',
  label: {
    align: 'start'
  },
  toggle: {
    background: 'background',
    color: 'background',
    knob: {
      extend: ({ theme }) => `
         box-shadow: ${
           theme.global.elevation[theme.dark ? 'dark' : 'light'].small
         };
         border: 1px solid ${
           theme.global.colors.border[theme.dark ? 'dark' : 'light']
         }
      `
    },
    extend: ({ checked, theme }) => `
      ${
        checked &&
        `background-color: ${
          theme.global.colors.green[theme.dark ? 'dark' : 'light']
        };`
      }
    `
  },
  extend: ({ disabled, theme }) => `
    ${
      !disabled &&
      `:hover {
      background-color: ${
        theme.global.colors['background-contrast'][
          theme.dark ? 'dark' : 'light'
        ]
      };
    }`
    }
    font-weight: 500;
    font-size: 14px;
    width: auto;
    padding: ${theme.global.edgeSize.xsmall} ${theme.global.edgeSize.small};
  `
}
