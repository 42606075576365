export default {
  panel: {
    border: {
      side: 'horizontal',
      color: 'border'
    }
  },
  heading: {
    margin: { vertical: 'medium', horizontal: 'xsmall' }
  },
  hover: {
    heading: {
      color: undefined
    }
  },
  border: undefined,
  icons: {
    color: 'text'
  }
}
