export default {
  content: {
    background: 'background',
    border: {
      color: 'border-weak'
    },
    margin: 'xxsmall',
    elevation: 'small',
    pad: {
      vertical: 'none',
      horizontal: 'small'
    },
    round: 'xsmall'
  }
}
