export default {
  color: 'text-strong',
  level: {
    1: {
      small: {
        size: '36px',
        height: '42px',
        maxWidth: '854px'
      },
      medium: {
        size: '53px',
        height: '59px',
        maxWidth: '1277px'
      },
      large: {
        size: '88px',
        height: '94px',
        maxWidth: '2122px'
      },
      xlarge: {
        size: '124px',
        height: '130px',
        maxWidth: '2966px'
      }
    },
    2: {
      small: {
        size: '31px',
        height: '37px',
        maxWidth: '749px'
      },
      medium: {
        size: '44px',
        height: '50px',
        maxWidth: '1066px'
      },
      large: {
        size: '58px',
        height: '64px',
        maxWidth: '1382px'
      },
      xlarge: {
        size: '71px',
        height: '77px',
        maxWidth: '1699px'
      }
    },
    3: {
      small: {
        size: '27px',
        height: '33px',
        maxWidth: '643px'
      },
      medium: {
        size: '36px',
        height: '42px',
        maxWidth: '854px'
      },
      large: {
        size: '44px',
        height: '50px',
        maxWidth: '1066px'
      },
      xlarge: {
        size: '53px',
        height: '59px',
        maxWidth: '1277px'
      }
    },
    4: {
      small: {
        size: '22px',
        height: '28px',
        maxWidth: '538px'
      },
      medium: {
        size: '27px',
        height: '33px',
        maxWidth: '643px'
      },
      large: {
        size: '31px',
        height: '37px',
        maxWidth: '749px'
      },
      xlarge: {
        size: '36px',
        height: '42px',
        maxWidth: '854px'
      }
    },
    5: {
      small: {
        size: '16px',
        height: '22px',
        maxWidth: '379px'
      },
      medium: {
        size: '16px',
        height: '22px',
        maxWidth: '379px'
      },
      large: {
        size: '16px',
        height: '22px',
        maxWidth: '379px'
      },
      xlarge: {
        size: '16px',
        height: '22px',
        maxWidth: '379px'
      }
    },
    6: {
      small: {
        size: '14px',
        height: '20px',
        maxWidth: '326px'
      },
      medium: {
        size: '14px',
        height: '20px',
        maxWidth: '326px'
      },
      large: {
        size: '14px',
        height: '20px',
        maxWidth: '326px'
      },
      xlarge: {
        size: '14px',
        height: '20px',
        maxWidth: '326px'
      }
    }
  },
  weight: 700
}
