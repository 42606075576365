export default {
  small: {
    size: '16px',
    height: '22px',
    maxWidth: '379px'
  },
  medium: {
    size: '18px',
    height: '24px',
    maxWidth: '432px'
  },
  large: {
    size: '22px',
    height: '28px',
    maxWidth: '538px'
  },
  xlarge: {
    size: '27px',
    height: '33px',
    maxWidth: '643px'
  },
  xxlarge: {
    size: '36px',
    height: '42px',
    maxWidth: '854px'
  }
}
