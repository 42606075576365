export default {
  extend: () => `
    font-size: 14px;
    min-width: 280px;
    margin: 0 auto;
  `,
  container: {
    extend: ({ theme }) => `
      svg {
        fill: ${
          theme.global.colors['text-strong'][theme.dark ? 'dark' : 'light']
        };
        stroke: ${
          theme.global.colors['text-strong'][theme.dark ? 'dark' : 'light']
        };
      }
      height: 40px;
      display: flex;
      flex-flow: column;
      justify-content: center;
    `
  }
}
