import { FormNext } from 'grommet-icons/icons/FormNext'
import { FormPrevious } from 'grommet-icons/icons/FormPrevious'

export default {
  icons: {
    next: FormNext,
    previous: FormPrevious
  },
  small: {
    fontSize: '13.6px',
    lineHeight: 1.375,
    daySize: '27.43px'
  },
  medium: {
    fontSize: '18px',
    lineHeight: 1.45,
    daySize: '54.86px'
  },
  large: {
    fontSize: '31.2px',
    lineHeight: 1.11,
    daySize: '109.71px'
  }
}
