import { FormTrash } from 'grommet-icons/icons/FormTrash'

export default {
  border: {
    size: 'xsmall'
  },
  button: {
    border: {
      radius: '4px'
    },
    pad: {
      vertical: '6px',
      horizontal: '12px'
    },
    color: 'text-strong',
    font: {
      weight: 'bold'
    },
    hover: {
      background: 'background-contrast'
    }
  },
  dragOver: {
    background: 'background-contrast',
    border: 'none'
  },
  hover: {
    border: {
      color: 'border'
    }
  },
  icons: {
    remove: FormTrash
  },
  message: {
    color: 'text-weak'
  },
  pad: { horizontal: 'xsmall' },
  extend: 'border-radius: 4px;'
}
