export default {
  color: 'text-strong',
  border: {
    radius: '4px'
  },

  /** Default */
  default: {
    color: 'text-strong',
    border: undefined,
    font: {
      weight: 600,
      fontSize: '10px'
    },
    padding: {
      vertical: '8px',
      horizontal: '24px'
    }
  },

  /** Primary */
  primary: {
    background: {
      color: 'brand'
    },
    border: undefined,
    color: '#FFF',
    font: {
      weight: 600
    },
    padding: {
      vertical: '8px',
      horizontal: '24px'
    }
  },

  /** Secondary */
  secondary: {
    border: {
      color: 'brand',
      width: '2px'
    },
    color: 'text-strong',
    font: {
      weight: 600
    },
    padding: {
      vertical: '8px',
      horizontal: '24px'
    }
  },

  /** Toolbar */
  toolbar: {
    border: {
      color: 'border',
      width: '1px'
    },
    color: 'text-strong',
    font: {
      weight: 600
    }
  },

  /** Option */
  option: {
    color: 'text',
    border: {
      radius: '0px'
    },
    font: {
      weight: 600
    }
  },

  /** Status Active */
  active: {
    background: {
      color: 'background-contrast'
    },
    color: 'text',
    secondary: {
      border: {
        color: 'transparent'
      }
    },
    option: {
      background: {
        color: 'active-background'
      }
    }
  },

  /** Status Selected */
  selected: {
    option: {
      background: 'selected-background',
      color: 'selected-text'
    }
  },

  /** Status Disabled */
  disabled: {
    background: {
      color: 'status-disabled'
    },
    border: {
      color: 'transparent',
      width: '2px'
    },
    padding: {
      vertical: '8px',
      horizontal: '24px'
    },
    color: '#7486a0',
    opacity: 1.0
  },

  /** Status Hover */
  hover: {
    default: {
      background: {
        color: 'background-contrast'
      },
      color: undefined
    },
    primary: {
      background: {
        color: 'brand2'
      }
    },
    secondary: {
      background: {
        color: 'active-background'
      }
    },
    option: {
      background: 'active-background',
      color: 'active-text'
    },
    toolbar: {
      background: {
        color: 'background-contrast'
      },
      color: undefined
    }
  },

  /** Button Sizes */
  size: {
    small: {
      border: {
        radius: '4px'
      },
      pad: {
        vertical: '4px',
        horizontal: '8px'
      }
    },
    medium: {
      border: {
        radius: '4px'
      },
      pad: {
        vertical: '6px',
        horizontal: '12px'
      }
    },
    large: {
      border: {
        radius: '6px'
      },
      pad: {
        vertical: '6px',
        horizontal: '16px'
      }
    }
  }
}
