/**
 *  Base Colors (Blue, Green, Teal, Purple, etc)
 */
const base = {
  white: {
    dark: '#FFF',
    light: '#FFF'
  },
  blue: {
    dark: '#00567A',
    light: '#00C8FF'
  },
  green: {
    dark: '#3daeab',
    light: '#3daeab'
  },
  teal: {
    dark: '#117B82',
    light: '#82FFF2'
  },
  purple: {
    dark: '#6633BC',
    light: '#F740FF'
  },
  red: {
    dark: '#A2423D',
    light: '#FC6161'
  },
  orange: {
    dark: '#9B6310',
    light: '#FFBC44'
  },
  yellow: {
    dark: '#8D741C',
    light: '#FFEB59'
  },
  'white!': '#FFFFFF',
  'blue!': '#00739D',
  'green!': '#01A982',
  'teal!': '#00E8CF',
  'yellow!': '#FEC901',
  'purple!': '#7630EA',
  'red!': '#C54E4B',
  'orange!': '#FF8300'
}

/**
 *  Background Colors
 */
const background = {
  background: {
    dark: '#263040',
    light: '#d1d8e0'
  },
  'background-back': {
    dark: '#263040',
    light: '#EFEFEF'
  },
  'background-front': {
    dark: '#404B5C',
    light: '#FFFFFF'
  },
  'background-contrast': {
    dark: '#FFFFFF14',
    light: '#0000000A'
  },
  'background-darker': {
    dark: '#1f2836',
    light: '#ccc'
  }
}

/**
 *  Text Colors
 */
const text = {
  icon: 'text',
  text: {
    dark: '#d1d8e0',
    light: '#444444'
  },
  'text-strong': {
    dark: '#FFFFFF',
    light: '#000000'
  },
  'text-weak': {
    dark: '#FFFFFF',
    light: '#BBBBBB'
  },
  placeholder: 'text-weak',
  'text-xweak': 'text-weak'
}

/**
 *  Neutral Colors
 */
const neutral = {
  neutral1: '#2d3040',
  neutral2: '#f8f8f8',
  neutral3: '#cfd2d7',
  neutral4: '#9fa2a9',
  neutral5: '#ffffff'
}

/**
 *  Brand Colors
 *
 */
const brand = {
  brand: '#3daeab',
  brand2: '#298787',
  brand3: '#185e61',
  brand4: '#0c383b'
}

/*
const primary: Types.IColorsPallete = {

  brand: '#1c7b74',
  brand2: '#38ada9',
  brand3: '#55efc4',
  brand4: '#00b894',
  
  base: '#3daeab',
  1: '#dfedea',
  2: '#d3e0de',
  3: '#b0d4cf',
  4: '#85c7c0',
  5: '#5fbab4',
  6: '#3daeab',
  7: '#298787',
  8: '#185e61',
  9: '#0c383b',
  10: '#041314'
}
*/

/**
 *  Border Colors
 */
const borders = {
  border: {
    dark: '#7887A1',
    light: '#999999'
  },
  'border-strong': {
    dark: '#AFBCD2',
    light: '#666666'
  },
  'border-weak': {
    dark: '#606B7D',
    light: '#BBBBBB'
  }
}

/**
 *  States Colors (Active, Disabled, Selected)
 */
const states = {
  control: 'green',
  focus: 'teal!',
  'active-background': 'background-contrast',
  'active-text': 'text',
  'disabled-text': 'text-weak',
  'selected-background': 'green',
  'selected-text': 'text-strong',
  'status-disabled': 'status-unknown'
}

/**
 *  Feedback Colors (Critical, Warning, Ok)
 */
const feedback = {
  'status-critical': {
    dark: '#D04F4E',
    light: '#FC5A5A'
  },
  'status-warning': {
    dark: '#9B6310',
    light: '#FFBC44'
  },
  'status-ok': {
    dark: '#008567',
    light: '#17EBA0'
  },
  'status-unknown': {
    dark: '#4F5F76',
    light: '#CCCCCC'
  },
  'validation-critical': {
    light: '#FC61613D',
    dark: '#C54E4B5C'
  },
  'validation-ok': {
    light: '#17EBA03D',
    dark: '#00856759'
  },
  'validation-warning': {
    light: '#FFBC443D',
    dark: '#9B63105C'
  },
  feedback1: '#5daf8f',
  feedback2: '#3a65b9',
  feedback3: '#c2463d',
  feedback4: '#ffc062'
}

/**
 *  Graph Colors
 */
const graph = {
  'graph-0': 'orange!',
  'graph-1': 'blue!',
  'graph-2': 'purple!',
  'graph-3': 'yellow!',
  'graph-4': 'teal!'
}

export default {
  ...base,
  ...background,
  ...neutral,
  ...brand,
  ...text,
  ...borders,
  ...states,
  ...feedback,
  ...graph
}
