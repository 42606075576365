export default {
  drop: {
    align: {
      top: 'bottom',
      left: 'left'
    }
  },
  icons: {
    color: 'text-strong'
  }
}
