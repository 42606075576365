export default {
  thumb: {
    color: 'green'
  },
  track: {
    lower: {
      color: 'green'
    },
    upper: {
      color: 'border'
    }
  }
}
