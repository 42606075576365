import { Ascending } from 'grommet-icons/icons/Ascending'
import { Descending } from 'grommet-icons/icons/Descending'
import { Unsorted } from 'grommet-icons/icons/Unsorted'

export default {
  body: {
    extend: ({ theme }) => `
      /* Margin and padding allow room for focus on table body */
      margin: ${theme.global.edgeSize.xxsmall} 0px;
      padding: 0px ${theme.global.edgeSize.xxsmall};
    `
  },
  header: {
    border: { side: 'bottom' },
    color: 'text-strong',
    extend: ({ column, sort, sortable, theme }) =>
      `
        ${
          sort &&
          sort.property === column &&
          `
          background: ${
            theme.global.colors['background-contrast'][
              theme.dark ? 'dark' : 'light'
            ]
          }
        `
        };
        ${
          sortable &&
          sort &&
          sort.property !== column &&
          `
            svg {
              opacity: 0;
            }
            :hover {
              svg {
                opacity: 1;
              }
            }
          `
        };
      `,
    font: {
      weight: 'bold'
    },
    gap: 'none',
    hover: {
      background: {
        color: 'background-contrast'
      }
    },
    pad: { horizontal: 'small', vertical: 'xsmall' }
  },
  icons: {
    ascending: Ascending,
    descending: Descending,
    sortable: Unsorted
  },
  /* Add FireFox work around until it adds support for backdrop-filter
  https://developer.mozilla.org/en-US/docs/Web/CSS/backdrop-filter
  */
  pinned: {
    header: {
      background: { opacity: 0.95 },
      extend: 'backdrop-filter: blur(8px);'
    },
    body: {
      background: { opacity: 0.95 },
      extend: 'backdrop-filter: blur(8px);'
    },
    footer: {
      background: { opacity: 0.95 },
      extend: 'backdrop-filter: blur(8px);'
    }
  },
  resize: {
    hover: {
      border: {
        color: 'border-strong',
        size: 'small'
      }
    }
  }
}
